<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-tabs color="deep-purple accent-4">
            <v-tab>Pending Orders</v-tab>
            <v-tab>All Orders</v-tab>

            <v-tab-item class="mt-10">
              <v-data-table
                :headers="dessertHeaders"
                :items="pending_orders"
                :loading="loading"
                loading-text="Loading... Please wait"
                no-data-text="No order available"
                item-key="id"
                class="elevation-0"
                style="background: #f5f5f5"
              >
                <template v-slot:top>
                  <v-toolbar
                    elevation="2"
                    style="background: #f5f5f5; font-size: 26px"
                  >
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-btn
                    color="primary"
                    style="padding-left: 0px !important"
                    text
                    @click="Approve(item)"
                    >Details</v-btn
                  >
                </template>

                <template v-slot:item.dateaction="{ item }">
                  <p class="mb-0">
                    {{ new Date(item.date).toDateString() }}
                  </p>
                </template>

                <template v-slot:item.leng="{ item }">
                  <p class="mb-0">
                    {{ item.transaction_ids.length }}
                  </p>
                </template>
              </v-data-table>
            </v-tab-item>

            <v-tab-item class="mt-10">
              <v-data-table
                :headers="dessertHeaders"
                :items="all_orders"
                item-key="id"
                class="elevation-0"
                style="background: #f5f5f5"
              >
                <template v-slot:top>
                  <v-toolbar
                    elevation="2"
                    style="background: #f5f5f5; font-size: 26px"
                  >
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-btn
                    color="primary"
                    style="padding-left: 0px !important"
                    text
                    @click="details(item)"
                    >Details</v-btn
                  >
                </template>

                <template v-slot:item.dateaction="{ item }">
                  <p class="mb-0">
                    {{ new Date(item.date).toDateString() }}
                  </p>
                </template>

                <template v-slot:item.leng="{ item }">
                  <p class="mb-0">
                    {{ item.transaction_ids.length }}
                  </p>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    dessertHeaders: [
      {
        text: "Order ID",
        align: "start",
        value: "id",
        sortable: false,
      },
      { text: "Invoice ID", value: "invoice_id", sortable: false },
      { text: "Date", value: "ordered_date", sortable: false },
      { text: "Price ", value: "price_total", sortable: false },
      { text: "Order Status", value: "order_status", sortable: false },
      { text: "Admin Status", value: "admin_status", sortable: false },
      { text: "Delivery Status", value: "delivery_status", sortable: false },
      { text: "Invoice", value: "actions", sortable: false },
    ],
    orders: [],
    user: {},
    id: 0,
    flag: 0,
    loading: true,
    pending_orders: [],
    all_orders: [],
  }),
  methods: {
    getALlRequest() {
      axios
        .get(`Cart/inventory_pending_orders/${this.id}/${this.flag}/`)
        .then((response) => {
          this.loading = false;
          if (response.data.success) {
            this.pending_orders = response.data.data;
          }
        })
        .catch((err) => {
          this.loading = false;
        });

      axios
        .get(`Cart/inventory_orders/${this.id}/${this.flag}/`)
        .then((response) => {
          if (response.data.success) {
            this.all_orders = response.data.data;
          }
        })
        .catch((err) => {});
    },
    Approve(item) {
      this.$router.push({
        path: "/merchant-managerdashboard/pages/orders/approval",
        query: { id: item.id, hub_id: this.id, flag: this.flag },
      });
    },

    details(item) {
      this.$router.push({
        path: "/merchant-managerdashboard/pages/orders/invoice",
        query: { id: item.invoice_id, order_id :  item.id  , hub_id: this.id, flag: this.flag },
      });
    },
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.id = this.$route.query.id;
    this.flag = this.$route.query.flag;
    if (this.user.user_id && this.id && this.flag) {
      this.getALlRequest();
    }
  },
};
</script>


<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>




